
import { defineComponent, onMounted, ref } from "vue";
import BaseService from "@/services/admin/BaseService";
import Curso from "@/interfaces/Curso";
import VerCurso from "@/components/aluno/cursos/VerCurso.vue";

export default defineComponent({
  components: { VerCurso },

  setup() {
    const loading = ref<boolean>(false);
    const urlImg = ref<string>(process.env.VUE_APP_STORAGE_BASE);
    const cursos = ref<Curso[]>();
    const modal = ref();
    const aulasTotal = ref<{ [key: number]: number }>({});
    const aulasAssistida = ref<number[]>([]);

    const openModal = (curso: Curso): void => {
      modal.value.open(curso);
    };

    const getCursos = (): void => {
      loading.value = true;
      BaseService.list("/aluno/meus-cursos")
        .then((res) => {
          cursos.value = res.data;
          if (cursos.value?.length == 1) {
            openModal(cursos.value[0]);
          };
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (loading.value = false));
    };

    const contarAssuntos = (curso: Curso): string => {
      let count = 0;
      curso.disciplinas.forEach((disciplina) => {
        disciplina.assuntos.forEach(() => {
          count++;
        });
      });

      if (count != 1) {
        return `${count} assuntos`;
      }
      return `1 assunto`;
    };

    const contarAulas = (curso: Curso): string => {
      let count = 0;
      curso.disciplinas.forEach((disciplina) => {
        disciplina.assuntos.forEach((assunto) => {
          assunto.aulas.forEach(() => {
            count++;
          });
        });
      });

      if (count != 1) {
        return `${count} aulas`;
      }
      return `1 aula`;
    };

    const porcetangem = (curso: Curso, index: number): number[] => {
      let aulas = 0;
      let assistidas = 0;
      let porcentagem = 0;
      let decimal = 0;
      curso.disciplinas.forEach((disciplina) => {
        disciplina.assuntos.forEach((assunto) => {
          assunto.aulas.forEach((aula) => {
            if (new Date().getTime() > new Date(aula.data_exibicao).getTime()) {
              if (aula.aula_assistida) {
                assistidas++;
              }
              aulas++;
            }
          });
        });
      });
      porcentagem = (assistidas * 100) / aulas;
      decimal = porcentagem / 100;
      aulasTotal.value[index] = aulas;
      aulasAssistida.value[index] = assistidas;
      return [decimal, porcentagem];
    };

    onMounted(() => {
      getCursos();
    });

    return {
      loading,
      urlImg,
      cursos,
      getCursos,
      contarAulas,
      contarAssuntos,
      porcetangem,
      openModal,
      modal,
      aulasTotal,
      aulasAssistida,
    };
  },
});
